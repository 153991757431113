<template>
  <EstimatedAnnualEnergyConsumptionRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import EstimatedAnnualEnergyConsumptionRadioField from 'chimera/all/components/form/fields/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionRadioField'
import TSelectableWithTextField from 'chimera/all/components/elements/TSelectableWithTextField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'EstimatedAnnualEnergyConsumptionFormPart',

  components: {
    EstimatedAnnualEnergyConsumptionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      corporateChoices: [
        new Selectable('kWh', 'kWh', '0', {
          component: TSelectableWithTextField,
          textField: true,
        }),
        new Selectable(
          'Kann ich nicht einschätzen',
          'Kann ich nicht einschätzen',
          'Kann ich nicht einschätzen',
        ),
      ],

      consumerChoices: [
        new Selectable(
          '2.300 kWh (1 Person)',
          '2.300 kWh (1 Person)',
          '2.300 kWh (1 Person)',
        ),
        new Selectable(
          '3.400 kWh (2 Personen)',
          '3.400 kWh (2 Personen)',
          '3.400 kWh (2 Personen)',
        ),
        new Selectable(
          '4.100 kWh (3 Personen)',
          '4.100 kWh (3 Personen)',
          '4.100 kWh (3 Personen)',
        ),
        new Selectable(
          '4.600 kWh (4 Personen)',
          '4.600 kWh (4 Personen)',
          '4.600 kWh (4 Personen)',
        ),
        new Selectable(
          '5.300 kWh (5 Personen)',
          '5.300 kWh (5 Personen)',
          '5.300 kWh (5 Personen)',
        ),
        new Selectable(
          '5.400 kWh (6 Personen)',
          '5.400 kWh (6 Personen)',
          '5.400 kWh (6 Personen)',
        ),
        new Selectable(
          'Mehr als 5.400 kWh',
          'Mehr als 5.400 kWh',
          'Mehr als 5.400 kWh',
        ),
        new Selectable(
          'Ich kann es nicht einschätzen',
          'Ich kann es nicht einschätzen',
          'Ich kann es nicht einschätzen',
        ),
      ],
    }
  },

  computed: {
    /**
     * @returns {Selectable[]}
     */
    choices() {
      let marketSegment = this.$store.getters['lead/getData']('market-segment')
      if (typeof marketSegment === 'object' && marketSegment[0]?.value) {
        marketSegment = marketSegment[0].value
      }

      if (marketSegment === this.$t('field.marketSegment.consumer')) {
        return this.consumerChoices
      }

      return this.corporateChoices
    },
  },
}
</script>
