<template>
  <FormModal title="Art des Daches" show-back-btn>
    <template #body>
      <RoofTypeElectricityEstimationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import RoofTypeElectricityEstimationFormStep from '~/components/form/steps/roofTypeElectricityEstimation/RoofTypeElectricityEstimationFormStep'

export default {
  components: {
    RoofTypeElectricityEstimationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Art des Daches und geschätzter jährlicher Stromverbrauch',
      headDescription:
        'Geben Sie die Art des Daches und Ihre Schätzung des jährlichen Stromverbrauchs an.',
      path: '/angebotsanfragen/art-des-daches-und-geschatzter-jahrlicher-stromverbrauch',
      progressValue: 50,
      checkoutStep: 2,
    }
  },
}
</script>
